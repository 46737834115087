.main-div {
    @media #{$xs-layout} {
      h2 {
        font-size: 1rem;
        margin-top: 2rem;
        margin-bottom: 0rem;
      }
    }
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 2rem;
    padding-right: 8.5rem;
    padding-left: 8.5rem;
    @media #{$xs-layout} {
      padding-right: 2rem;
      padding-left: 2rem;
      p {
        font-size: 10px;
        line-height: 15px;
      }
    }
    h1 {
      text-transform: initial;
      text-align: center;
      padding: 2rem;
      @media #{$xs-layout} {
        font-size: 20px;
        line-height: 30px;
      }
    }
  
    h3 {
      text-transform: initial;
      padding-top: 2rem;
      padding-bottom: 2rem;
      @media #{$xs-layout} {
        font-size: 16px;
        text-align: center;
      }
    }
    .return-div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .return-button {
        background-color: #000;
        border-radius: 0px;
        color: #ffffff;
        letter-spacing: 1px;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        padding: 18px 63px 17px;
        text-transform: uppercase;
        @media #{$md-layout} {
          padding: 18px 25px 17px;
          font-size: 13px;
        }
        @media #{$xs-layout} {
          padding: 18px 40px 17px;
          margin: 0 0 15px;
        }
        &:hover {
          background-color: $theme-color;
          color: #fff;
        }
      }
    }
  
    .cart-table-content table tbody > tr td {
      font-size: 15px;
      text-align: start !important;
    }
    .cart-table-content table tbody > tr td.product-name a {
      font-size: 13px;
    }
  }
  
  .shop-review {
    width: 100%;
  }
  
  .text-start {
    text-align: initial;
  }